.login {

    display: flex;
    margin-top: 7rem;

}

.login__div {
    width: 30vw;
    height: 40vh;
    color: white;
    background-color: #3498db;
    margin: auto;
    box-shadow:
        0px 2px 10px rgba(0, 0, 0, 0.2),
        0px 10px 20px rgba(0, 0, 0, 0.3),
        0px 30px 60px 1px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    padding: 50px;
}

.login__text {
    font-size: 2.5rem;
    text-align: center;
}

.login__msg {
    text-align: center;
    font-size: 1.5rem;
}

.login__email {
    border: none;
    background: none;
    box-shadow: 0px 2px 0px 0px white;
    width: 100%;
    color: white;
    font-size: 1em;
    outline: none;
    padding-top: 2rem;
}

.login__password {
    border: none;
    background: none;
    box-shadow: 0px 2px 0px 0px white;
    width: 100%;
    color: white;
    font-size: 1em;
    outline: none;
    padding-top: 3rem;
}

.login__submit {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 1.3rem;
}

.login__submit:hover {
    background: white;
    color: dimgray;
    transition: 0.5s;
    cursor: pointer;
}

.login__email::placeholder {
    color: #D3D3D3;
}

.login__password::placeholder {
    color: #D3D3D3;
}