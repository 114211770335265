.header {
    color: white;
    width: 100vw;
    height: 10vh;
    background-color: #3498db;

    display: flex;
    justify-content: space-between;
}

.header__text {
    font-family: sans-serif;
    padding-top: 1.7rem;
    padding-left: 5rem;
    font-size: 1.5rem;
}

.header__logout {
    margin-right: 4rem;
    margin-top: 1.2rem;


}

.header__history__btn {

    font-family: sans-serif;

    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;

    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: -0.3rem;
    margin-right: 1.3rem;
}

.header__logout__btn {
    /* background: none;
    text-decoration: none;
    color: white;
    box-shadow: 0px 0px 0px 2px white;
    border-radius: 3px;
    padding: 5px 2em;
    transition: 0.5s;
    font-family: sans-serif;
    font-size: 1.1rem; */
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: -0.3rem;
}

.header__logout__btn:hover {
    background: white;
    color: dimgray;
    transition: 0.5s;
    cursor: pointer;
}