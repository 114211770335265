.interface__container {
    width: 100vw;
    height: 100vh;
    text-align: center;


}

.interface__buttons {
    width: 75vw;
    height: 70vh;
    font-family: sans-serif;
    margin-top: 3rem;
    margin-left: 11rem;
    display: flex;
    position: relative;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* 
.interface__buttons::before {
    content: "";
    position: absolute;
    width: 45vw;
    height: 88vh;
    background: linear-gradient(0deg, transparent, #45f3ff, #45f3ff);
    top: -50%;
    left: -50%;
    animation: animate 6s linear infinite;
    transform-origin: bottom right;
    border-radius: 100px;
}

.interface__buttons::after {
    content: "";
    position: absolute;
    width: 45vw;
    height: 88vh;
    background: linear-gradient(0deg, transparent, #45f3ff, #45f3ff);
    top: -50%;
    left: -50%;
    animation: animate 6s linear infinite;
    transform-origin: bottom right;
    animation-delay: 3s;
    border-radius: 100px;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} */

.interface__buttons__first {
    background: #3498db;
    text-decoration: none;
    color: white;
    box-shadow: 0px 0px 0px 2px white;
    padding: 5px 1em;
    transition: 0.5s;
    font-family: 'Comforter Brush', cursive;
    font-size: 1.1rem;
    margin-top: 4rem;
    text-align: center;
    border-radius: 5px;
    border: 0px;
    height: 3rem;
}



.interface__input {
    font-size: 0.9rem;
    padding-left: 3.5rem;
}



.datepicker__div {
    height: 8vh;
    /* background-color: black; */
    margin-top: 4rem;
    font-size: 1.2rem;
    color: black;

}

.datepicker__div__first {
    margin-top: 1rem;
    margin-left: 1.2rem;

}

#start {
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    background-color: #fff;
    padding: 3px 5px;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 190px;
}

.interface__buttons__animation {
    width: 42vw;
    height: 70vh;
    background-color: white;
    color: black;
}

.test.interface__department {
    color: black;
    font-size: 1.3rem;
    margin-left: 65px;
}

.nodeps__holder {


    margin-top: 1rem;
    width: 55vw;
    height: 5vh;


}

.select {
    margin-left: -35.5rem;
    background: none;
    text-decoration: none;
    color: black;
    box-shadow: 0px 0px 0px 2px white;
    border-radius: 3px;
    padding: 5px 2em;
    transition: 0.5s;
    font-family: sans-serif;
    font-size: 1.1rem;
}

.select__department {
    width: 20rem;
    font-size: 1rem;

}

.selet:hover {
    background: black;
    color: white;
    transition: 0.5s;
    cursor: pointer;
}

.select__btn {
    margin-left: 1rem;
    background: #007bff;
    text-decoration: none;
    color: black;
    box-shadow: 0px 0px 0px 2px white;
    border-radius: 3px;
    padding: 5px 2em;
    transition: 0.5s;
    font-family: sans-serif;
    font-size: 1.1rem;
    border-radius: 20px;


}

.select_button_div {
    margin-top: -2.9rem;
    margin-left: 7rem;
}

.select_button {
    margin-left: 5rem;
    appearance: button;
    backface-visibility: hidden;
    background-color: #3498db;
    border-radius: 6px;
    border-width: 0;
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .1) 0 2px 5px 0, rgba(0, 0, 0, .07) 0 1px 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif;
    font-size: 100%;
    height: 50px;
    outline: none;
    overflow: hidden;
    padding: 0 15px;
    text-align: center;
    transition: all .2s, box-shadow .08s ease-in;
    touch-action: manipulation;
    width: 24rem;
}

.noerrors__msg {
    width: 30rem;
    height: 4rem;
    margin: auto;
    margin-top: 1rem;
    background-color: #2b7515;
    border-radius: 15px;
}

.noerrors__msg__p {
    padding-top: 1.2rem;
}

.nodepartment__msg {
    width: 30rem;
    height: 4rem;
    margin: auto;
    margin-top: 1rem;
    background-color: #ba3939;
    border-radius: 15px;

}

.nodepartment__msg__p {
    padding-top: 1.2rem;
    color: whitesmoke;
}

.interface__buttons__div {
    /* margin-left: 2rem; */

    width: 35vw;
    height: 70vh;
    background-color: #3498db;

}

.interface__buttons__second {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;
}

/* .btn.second {
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px; */
/* font-size: 14px; */
/* font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;
} */


.interface__buttons__third {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;

}

.interface__buttons__fourth {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;
    width: 330px;
}

.interface__buttons__fifth {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: white;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;
    width: 330px;
}

.interface__buttons__second:hover, .interface__buttons__third:hover, .select__btn:hover {
    background: #2980b9;
    color: white;
    transition: 0.5s;
    cursor: pointer;
}

.logo__div {
    width: 10vw;
    height: 10vh;
    margin-left: 6rem;
    margin-top: 2rem;
    position: relative;

}

.logo {
    position: absolute;
    object-fit: contain;
    width: 10vw;
    height: 10vh;
}

.third:hover {
    background-color: #3498db;
    color: white;
    transition: 0.5s;
    cursor: pointer;
}

.triangle__holder {
    background-color: #2980b9;
    height: 60vh;
    margin-top: -8px;

}

.triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    margin: auto;
    border-color: transparent transparent #2980b9 transparent;
}

.triangle__hr {
    margin-top: -2px;
    border: 1px solid #2980b9;
}

.interface__reset__btn {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: #3498db;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 3rem;
    width: 330px;
    color: white;
}

.interface__reset__btn:hover {
    background-color: #2980b9;
    cursor: pointer;
}

[type="date"] {
    background: #fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png) 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
    display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.test {
    display: flex;
}

.test2 {
    display: flex;
    margin-left: 41px;
}

.SelectBox {
    position: relative;

}

.SelectBox select {
    width: 300px;
    height: 35px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .2);
    border: none;
    outline: none;
    padding-left: 5px;
    font-size: 17px;
    cursor: pointer;
    width: 20rem;
}

.SelectBox .chevron div::after {
    content: '\25BC';
}

.SelectBox .chevron {
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    line-height: 35px;
    width: 30px;
    height: 100%;
    background-color: #3498db;
    pointer-events: none;
    color: #fff;
}

.SelectBox2 {
    position: relative;
    margin-bottom: 4px;

}

.SelectBox2 select {
    width: 300px;
    height: 35px;
    background-color: white;
    color: black;
    -webkit-appearance: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .2);
    border: none;
    outline: none;
    padding-left: 5px;
    font-size: 17px;
    cursor: pointer;
    width: 9rem;
}

.SelectBox2 .chevron div::after {
    content: '\25BC';
}

.SelectBox2 .chevron {
    position: absolute;
    top: 0px;
    right: 0px;
    text-align: center;
    line-height: 35px;
    width: 30px;
    height: 100%;
    background-color: #3498db;
    pointer-events: none;
    color: #fff;
}

.interface__edit__btn {
    font-family: sans-serif;
    /* color: #000000; */
    font-size: 18px;
    background: #3498db;
    padding: 12px 16px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    /* font-size: 14px; */
    font-weight: 400;
    -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 1.5rem;
    width: 330px;
    color: white;
}