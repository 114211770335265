.excelbuttons__div {
    display: flex;
    justify-content: space-between;
}

.excel__btn {
    font-family: sans-serif;
    font-size: 18px;
    background: #3498db;
    padding: 12px 10px;
    cursor: pointer;
    border-width: 0px;
    border-radius: 5px;
    font-weight: 400;
    box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
    position: relative;
    margin-bottom: 20px;
    transition: 0.3s;
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 130px;
    color: white;
}

.excel__btn:hover {
    background-color: #2980b9;
    cursor: pointer;
}